import { navigate } from "gatsby"
import React, { useState, useRef, useEffect } from "react"
import { useOnWindowScroll } from "rooks"
import axios from "axios"
import Seo from "../components/seo"
import styled from "styled-components"
const SinglePage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1;
`
const ContentWrap = styled.div`
  position: absolute;

  max-width: 1200px;
  padding: 0 0 0 0;
  z-index: 1000;
  grid-column-start: 2;
  grid-column-end: 24;
  @media (min-width: 768px) {
    grid-column-start: 6;
    grid-column-end: 21;
  }
  @media (min-width: 1200px) {
    grid-column-start: 8;
    grid-column-end: 18;
  }
  @media (min-width: 1440px) {
    grid-column-start: 7;
    grid-column-end: 19;
  }
  position: relative;
  margin-top: 30px;
  padding-top: 150px;
  padding-bottom: 120px;
  font-family: "PPneueregular";
  color: var(--white);
  .content {
    margin: 30px 0;
    @media (min-width: 768px) {
      margin: 70px 0;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

export default function ContactPage() {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/{unique-endpoint-generated-on-step-1}",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <>
      <Seo title="Blackpower Barbie" />
      <SinglePage>
        <div className="grid24">
          <ContentWrap>
            <div className="content">
              <form onSubmit={handleOnSubmit}>
                <input type="email" name="email" placeholder="Your Email" />
                <input type="text" name="name" placeholder="Your Name" />
                <input type="text" name="message" placeholder="Your Message" />
                <button type="submit">Send</button>
              </form>
            </div>
          </ContentWrap>
        </div>
      </SinglePage>
    </>
  )
}
